import React from 'react';
import Img from "gatsby-image";
import SEO from '../components/seo';
import Layout from '../components/layout';

function BlogPost(props) {
    const node = props.data.markdownRemark;
    const { title, author, date } = node.frontmatter;
	return (
		<Layout>
			<SEO
				title={node.frontmatter.title}
				keywords={node.frontmatter.tags}
			/>
			<div className="blog-post">
				<div className="flex mb-5">
					<div className="w-3/4 lg:w-5/6 flex items-center">
						<div>
							<h1 className="w-full">{title}</h1>
							<div className="w-full mt-2 mb-5">
								{author} :: {date}
							</div>
						</div>
					</div>
					<div className="w-1/4 lg:w-1/6 flex items-center">
						<Img fluid={node.frontmatter.cover_image.childImageSharp.fluid} className="w-full ml-3"  alt={node.frontmatter.title} />
					</div>
				</div>

				<div dangerouslySetInnerHTML={{ __html: node.html }} />
			</div>
		</Layout>
	)
}

export default BlogPost;

export const query = graphql`
	query PostQuery($slug: String!) {
		markdownRemark(fields: { slug: { eq: $slug } }) {
			html
			frontmatter {
				author
				date(formatString: "MMMM Do, YYYY")
				title
				cover_image {
					publicURL
					childImageSharp {
						fluid(maxWidth: 600) {
							...GatsbyImageSharpFluid
						}
					}
				}
			}
		}
	}
`